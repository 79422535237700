<template>
  <wysiwyg v-model="localValue" :options="options" />
</template>

<script>
export const options = {
  hideModules: {
    justifyLeft: true,
    justifyRight: true,
    justifyCenter: true,
    code: true,
    table: true,
    headings: true,
    link: true,
    removeFormat: true,
    separator: true,
  },
};
export default {
  name: "NoteEditor",
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(v) {
        const noColor = v?.replace(/[^-]color:.*?;/g, "");
        this.$emit("input", noColor);
      },
    },
    options() {
      return options;
    },
  },
  created() {
    if (this.$vuetify.theme.dark) import("./dark.css");
  },
};
</script>

<style>
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>
